import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom";

import Landing from "./components/Landing";
import Secret from "./components/SecretPage/Secret";
import history from "./history";
export default class Routes extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={Landing} />
          <Route path="/Photobooth" component={Secret} />
        </Switch>
      </Router>
    )
  }
}
