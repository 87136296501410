import React, { useState, useEffect } from 'react';
import { Scrollama, Step } from 'react-scrollama';
import axios from "axios"

const quotes = [
    "Usually, graduation would be the most important thing on seniors' minds as spring quarter comes to a close. This year, the world trembles under the strain of a global pandemic and raw pain from generations of racial injustice.",
"The recent deaths of Breonna Taylor, Ahmaud Arbery and George Floyd have prompted organizers, supporters and allies of the Black Lives Matter movement to take to the streets and protest police brutality and racial injustice.",
"After George Floyd was killed by a white police officer in Minneapolis, protests spread worldwide. In California, people joined the movement to protest in solidarity.",
"Police have met protesters throughout Los Angeles with tear gas, rubber bullets and arrests.",
"LAPD held dozens of detained protesters at Jackie Robinson Stadium. Detainees were handcuffed and sat for hours without access to water, food or bathrooms.",
"More protests are slated to occur throughout finals week and beyond, all against the backdrop of a once-in-a-generation crisis: COVID-19.",
"There are more than 100,000 confirmed cases of COVID-19 in California alone, 36 of them are within the UCLA community.",
"To honor the Class of 2020's journey at UCLA is to honor the loss they've endured alongside their many accomplishments. From losing time with loved ones and experiences at UCLA, to job offers rescinded and a graduation ceremony delayed, the Class of 2020 is grieving.",
"We are past the tipping point. Where do we go from here?",
"Welcome to the Daily Bruin's 2020 Graduation Issue", 
]

const imageURLs = [
    "12.AL.jpeg",
    "12.AL.jpeg",
    "MC.12.jpg",
    "MC.16.jpg",
    "LGK.8922.jpg",
    "LGK.7933.jpg",
    "LGK.7933.jpg",
    "web.corona.adx.jpg",
    "none",
    "none"
]

const mql = window.matchMedia(`(min-width: 800px)`);

const ProtestScroll = (props) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(null);
/*   const [slides, setSlides] = useState(null); */
  let imageNum = 0;  
/*     const kerckhoff_url = "https://kerckhoff.dailybruin.com/api/packages/flatpages/interactive.2020.grad-issue/"
    axios.get(kerckhoff_url).then(result => {
     setSlides(result.data.data["scrollyprotest.aml"]["quotes"]);
    }).catch((error) => {
     console.log("error!" + error)
    }); */
/*   useEffect(() => {    
    const kerckhoff_url = "https://kerckhoff.dailybruin.com/api/packages/flatpages/interactive.2020.grad-issue/"
    axios.get(kerckhoff_url).then(result => {
        setSlides(result.data.data["scrollyprotest.aml"]["quotes"]);
    }).catch((error) => {
        console.log("error!" + error)
    });
    setCurrentStepIndex(1);
    }, []) */

  
 
  // This callback fires when a Step hits the offset threshold. It receives the
  // data prop of the step, which in this demo stores the index of the step.
  const onStepEnter = ({ data }) => {
    setCurrentStepIndex(data);
    if (data == quotes.length - 1 ) {
        props.setIsBottom(true)
    } else if (data < (quotes.length - 1)) {
        props.setIsBottom(false)
    }
  };

/*   if (!slides) {
      return null
  } */
  if (!currentStepIndex) {
      imageNum = 0;
  } else {
      imageNum = currentStepIndex
  }

  return (
      <div>
    <div style={{ position: 'sticky', top: '0vh', zIndex: '-2', height: '100vh', backgroundColor: 'black' }}>
      </div>
      <div style={{ position: 'sticky', top: '5vh', zIndex: '-1', height: '90vh', backgroundImage: `url(${imageURLs[currentStepIndex]})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
      </div>
      <Scrollama onStepEnter={onStepEnter} offset={0.95}>
        {Array.from(Array(quotes.length).keys()).map((_, stepIndex) => (
          <Step data={stepIndex} key={stepIndex}>
            <div
              style={{
                margin: '80vh auto',
                width: 'max(25vw, 200px)',
                padding: '5vh 5vw',
                fontSize: mql.matches ? '1.5rem' : '0.8rem',
                overflowY: mql.matches ? null : 'scroll', 
                fontFamily: 'Libre Baskerville',
                borderRadius: '15px', 
                textAlign: 'left',
                border: '0.1rem solid white',
                backgroundColor: 'black',
                color: 'white',
                boxSizing: "border-box"
              }}
            >
              {quotes[stepIndex]}
            </div>
          </Step>
        ))}
      </Scrollama>
      <div style={{ height: '10vh', backgroundColor: 'black' }}>
      </div>
    </div>
  );
};
 
export default ProtestScroll;