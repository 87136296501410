import React from "react";
import { css } from "emotion";
import styled from "styled-components";
import axios from "axios"
import history from "../history";
import SectionPage from "./SectionPage";
import Sidebar from "./Sidebar/Sidebar";
import { Button } from 'react-bootstrap';
import Gallery from "./Gallery"
// import "./landing.css"
import ReactDOM from 'react-dom';
import Thirty from "./Thirty"
import About from "./About/index"
import Collapsable from "./Collapsable"
import VizSensor from 'react-visibility-sensor';
import ProtestScroll from "./ProtestScroll/ProtestScroll";

const Title = styled("div")`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`;
const onButton = () => {
  history.push('/Secret')
}
const section_names = [
  "PROTESTS",
  "NEWS",
  "SPORTS",
  "ARTS",
  "OPINION",
  "MULTIMEDIA",
  "PRIME",
  "QUAD",
  "ENTERPRISE",
  "THIRTY",
  "ABOUT"
];
export default class Landing extends React.Component {

  state = {
    cards_data: null,
    landing_images: [],
    isBottom: false
  }

  componentWillMount(){
    const kerckhoff_url = "https://kerckhoff.dailybruin.com/api/packages/flatpages/interactive.2020.grad-issue/"
    axios.get(kerckhoff_url).then(result => {
      
      // honestly, when we retrieve data from kerckhoff, 
      // we should lowercase all keys.
      // in google drive, when you start typing aml, it auto capitializes the first letter
      // so that messes up our code cuz our code is only designed to use lowercase fields.
      this.setState({
        ...this.state, 
        cards_data: result.data.data["cards.aml"],
        landing_images: result.data.data["landing.aml"]["imageUrls"],
        thirty: result.data.data["30.aml"]["people"],
        about: result.data.data["about.aml"]["content"]
      });
    }).catch((error) => {
      console.log("error!" + error)
    });
    
  }

  handleScroll = (e) => {
    this.forceUpdate()
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  setIsBottom = (value) => {
    this.setState({isBottom: value})
  }

  render() {
    const {isBottom} = this.state 
    const section_pages = section_names.map(name => (
      (this.state.cards_data !== null  && this.state.cards_data[name] !== [] && name !== "THIRTY" && name != "ABOUT") ? <SectionPage 
        section={name} 
        cards={this.state.cards_data !== null? this.state.cards_data[name]: []} /> : null
    ))
    return (
      <div>
        <VizSensor
        onChange={(isVisible) => {
          this.setState({isBottom: !isVisible})
        }}
        partialVisibility={true}
      > 
      <div>
          <Gallery />
        {this.props.visited ||  
        <ProtestScroll setIsBottom={this.setIsBottom} /> 
      } </div> 
      </VizSensor>
        {isBottom ? <Sidebar sections={section_names} open={true} sidebarOpen={true} sidebarDocked={true} setIsBottom={this.setIsBottom}/> : null}
        <Collapsable />
        {section_pages}

        <Thirty people={this.state.thirty}/>
        <About data={this.state.about}/>
      </div>
    );
  }
}
