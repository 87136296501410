import React from "react";
import "./style.css"

export default function ArticleGrid(props) {
	if(!props.people)
		return null;

	let cleaned = clean(props.people)

	let cards = cleaned.map(x => <div className="card">
		<img className="card-img" src={x.image}/>
		<a className="name" href={x.link}> {x.name} </a>
	</div>)

	cards = appendFiller(cards)

	return (<div
		id={"THIRTY_Section"}
		>
		<div className="sidebar-deal-with">
		<h1
		  style={{
			fontFamily: "Libre Baskerville",
			fontSize: "3em",
			color: "#84a1b6",
			textAlign: "center"
		  }}
		>
		-30-
		</h1>
		<div className="container">
			{cards}
		</div>
	</div>
	</div>)

}


function clean(objects) {
  // lowercase fields
  return objects.map(x => {
    let out = {}

    for (let key in x) {
        // so it doesn't copy prototype properties
        if (x.hasOwnProperty(key)) {
          out[key.toLowerCase()] = x[key]
        }
    }
    return out;
  })
}

function isMobile() {
  return window.innerWidth < 768;
}

function appendFiller(objects) {
  if (isMobile())
    // in mobile, everything is in one column so no alignment
    return objects;

  
  let out = objects.map(x => x); 
  let count = objects.reduce((accum, x) => accum + Number(x.width), 0); // sum (x.width for all objects)

  for (let i = 0; i < count % 3; i++) {
    // if the last row didn't fill everything
    out.push(<div className="card">
		
	</div>);

  }
  return out;
}