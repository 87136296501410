import React from "react";
import "./SidebarContent.css";
import history from '../../history'

function MoveToSection(section) {
  document.getElementById(section + "_Section").scrollIntoView();
}
const SidebarContent = props => {
  const sections = [];
  const sidebar_names = [
    "2020 protests",
    "news",
    "sports",
    "arts",
    "opinion",
    "multimedia",
    "prime",
    "the quad",
    "enterprise",
    "-30-",
    "about"
  ];
  for (const [e, val] of sidebar_names.entries()) {
    sections.push(
      <li class="sidebar_sections">
        <b onClick={() => MoveToSection(props.section_article_names[e])}>
          {val}
        </b>
      </li>
    );
  }
  return (
    <ul style={{ paddingInlineStart: 5, alignContent: "center" }}>
      <li>
        <b onClick={() => {window.scrollTo(0, 0); props.setIsBottom(false)}}>Grad Issue 2020</b>

      </li>
      <hr></hr>
      {sections}
    </ul>
  );
};

export default SidebarContent;