import React from "react";
import Sbar from "react-sidebar";
import SidebarContent from "./SidebarContent";

const mql = window.matchMedia(`(min-width: 800px)`);

export default class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarDocked: mql.matches, 
      sidebarOpen: props.sidebarOpen,
      sidebarShadow: false
    };

    this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
    mql.addListener(this.mediaQueryChanged);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
 
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });

  }

  mediaQueryChanged() {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  }

  render() {
    const { sidebarOpen, sidebarDocked, sidebarShadow } = this.state;
    const { sections, setIsBottom } = this.props;

    return (
      <>
      
      <Sbar
        sidebar={<SidebarContent section_article_names={sections} setIsBottom={setIsBottom}/>}
        onSetOpen={this.onSetSidebarOpen}
        open={sidebarOpen}
        styles={{
          sidebar: {
            background: "white",
            position: "fixed",
            borderRight: 0.5,
            borderRightColor: "gray",
            borderRightStyle: "solid",
            width: mql.matches ? 'min(10vw, 145px)' : '20vw',
            wordWrap: 'break-word',
          },
          content: { alignContent: "center" },
          overlay: {
            zIndex: 1,
            position: "fixed",
            top: 0,
            left: 0,
            opacity: 0,
            visibility: "hidden",
            transition: "opacity .3s ease-out, visibility .3s ease-out",
            backgroundColor: "rgba(0,0,0,0)"
          },
        }}
        docked={sidebarDocked}
        shadow={sidebarShadow}
        overlayId={"overlay"}
      ></Sbar>
      </>
    );
  }
}
