import React from 'react'
import "./landing.css"
import BannerAd from "../BannerAd"

export default function Gallery(props) {
	

	return (
		<div>
		  {/* Filler */}
          
          <div  className={"landing"}>
            <div className="year">
              2020
              <div  className="title">
                GRADUATION ISSUE &nbsp;
              </div>
            </div>
            <div  className="author">
              The Daily Bruin 
            </div>
            <div  className="ad">
                <BannerAd section="NEWS"/>
            </div>
          </div>
          
      
      	
          
        </div>
	)
}