import React from "react";
import ReactDOM from "react-dom";
import AvatarEditor from 'react-avatar-editor'
import Secret from "./Secret"

function Imageuploader(props) {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  //let currentState = 'u';

  const sendData = (file) => {
      props.parentCallBack(file);
  }
  const handleImageUpload = e => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
      sendData(file);
    }
    else {
        return (<div>ERROR</div>)
    }
  };
       
  return (
      <div>
            <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }}
                />
                <div
                style={{
                    height: "60px",
                    width: "60px",
                    border: "1px dashed black"
                }}
                onClick={() => imageUploader.current.click()}
                >
                <img
                    ref={uploadedImage}
                    style={{
                    width: "100%",
                    height: "100%",
                    position: "acsolute"
                    }}
                />
                </div>
                
                </div>
    </div>
    
  )
}
export default Imageuploader;
const rootElement = document.getElementById("root");
ReactDOM.render(<Imageuploader />, rootElement);
