import React from "react";
import "./Collapsable.css";

class Collapsible extends React.Component {
    constructor(props){
        super(props);
        this.state = {open: false}
        this.togglePanel = this.togglePanel.bind(this);
    }
    togglePanel(e){
    this.setState({open: !this.state.open})
    }
    render() {
        return (
            <div className='collapsible-entire'>
                <div onClick={(e)=>this.togglePanel(e)} className='collapsible-header'>
                    <h1>LETTER FROM THE EDITORS</h1>
​
                        Dear readers,
                        <br></br>
                        <br></br>
                        There’s a prickly, unpleasant irony in the fact that the Daily Bruin’s “Community Guide” currently leads to a 404 error.
                        <br></br>
                        <br></br>
                        It’s a microcosm of two truths at the heart of what we do. First, the Daily Bruin aims to provide accurate, fair and well-rounded news to our readers. Second, it inherently falls short of this mission when our newsroom lacks diverse perspectives to see stories in their entireties.  
                        <br></br>
                        <br></br>
                        To those marginalized communities who have experienced our paper’s historical and ongoing shortcomings most intensely, I am deeply sorry. Our staff sincerely wants and hopes and aims to do better. We know that we cannot provide the coverage our community deserves with the inherent limitations of unrepresentative voices. 
                        <br></br>
                        <br></br>
                        But, as our editorial board is prone to point out, talk is cheap. So here’s what we plan to do this year to help us take the next steps toward inclusivity as a paper.
                        <br></br>
                        <br></br>
                    
                </div>
                    <div onClick={(e)=>this.togglePanel(e)}>
                        . . . click to expand or condense letter . . .
                    </div>
                {this.state.open ? (
                <div onClick={(e)=>this.togglePanel(e)} className='collapsible-content'>
                    <br></br>
                    <li>Staffing and training: Compared to UCLA’s demographics, Daily Bruin’s staff is disproportionately made up of Asian, white and multiracial students. Black, Hispanic and Native American students are severely underrepresented. Without greater diversity, the Daily Bruin loses sight of whole sets of stories. </li>
                        <ul>
                        <li>- On April 23, the Daily Bruin hired its first Outreach director, Marilyn Chavez-Martinez. The new role, which expands the responsibilities of the pre-existing Alumni director, is intended to improve the Daily Bruin’s inclusivity, both within its own staff and in serving a more diverse cross-section of readers.  </li>
                        <li>- We plan to integrate in-depth sensitivity and implicit bias training into our September fall editor training and new intern trainings in roughly October and January. </li>
                        <li>- We plan to expand on our inaugural Diversity Report in the upcoming year to provide a clearer picture about our current configuration to our readers and for our own further evaluations. Our team will collect data on additional considerations, including transfer student status and major.</li>
                        <li>- We plan to make a concerted effort to solicit staff applications from on-campus organizations, including cultural organizations, the Transfer Student Center and the UCLA LGBTQ Campus Resource Center, in addition to our conventional department mailing list approach.</li>
                        </ul>
                    <li>Accessibility: Many Daily Bruin staffers work dozens of hours per week for either less than $5 per hour or no pay at all. This is a structural problem which disadvantages students from low-income or underrepresented backgrounds who may not be able to invest time into unpaid extracurricular activities, but whose voices are vital in ensuring high-quality journalism.</li>
                    <ul>
                        <li>- We aim to create a series of $100-$500 need-based scholarships for our staffers in collaboration with the Daily Bruin Alumni Network. This year, the DBAN plans to offer five $2,500 scholarships to staffers who want to pursue careers in media, an increase from four such scholarships in the 2019-2020 academic year.</li>
                        <li>- We aim to get internships with the Daily Bruin accredited for academic units. This proposal has yet to be finalized, but the current goal is to establish one to three pass/no pass units where “pass” determinations are left up to internal publication leadership. We hope to work with the newsmagazines to get some form of stand-in compensation set up across all UCLA Student Media publications.</li>
                        <li>- In addition to reaching out to on-campus organizations, we plan to form relationships with local community colleges to ease the transition for community college journalists into a UCLA publication.  </li>
                    </ul>
                    <li>Two-way communication: Information continually flows from the Daily Bruin to readers. The flaw in this model, however, is the limited solicitation of input from our readers. We aim to improve our transparency measures to enhance our outgoing communication.</li>
                    <ul>
                    Feedback
                    </ul>
                    <ul>
                    <ul>
                        <li>- We plan to release quarterly surveys beginning over the summer where readers can share their thoughts about our performance.</li>
                        <li>- Our Online department is working to create a reader-friendly tipline, which aims to more easily receive tips and feedback from our readership.</li>
                        <li>- We hope to establish a more collaborative relationship with the Student Media newsmagazines, whose fellow journalists have leveled thought-provoking criticisms against The Bruin. If collaboration is possible, we hope this feedback can help inform our growth as a newsroom.</li>
                    </ul>
                    </ul>
                    <ul>
                    Transparency
                    </ul>
                    <ul>
                    <ul>
                    <li>- If students indicate interest through the summer outreach survey, the Daily Bruin is open to hosting an event or events where readers can ask our editor team about the decisions and practices that go into producing The Bruin. </li>
                    <li>- Daily Bruin financial statements from 1990 to present are available online through the <a href='http://uclacommunicationsboard.org/financial-statements/'>Communications Board</a> website.</li> 
                    <li>- We plan to rewrite and publicize our Community Guide, complete with our ethics and sensitivity guidelines.</li> 
                    </ul>
                    </ul>
                    <ul>
                    <ul>
                    <ul>
                    <li>Note: We have heard and considered reader feedback which suggested the Daily Bruin should make direct monetary contributions to social and political causes, but we are unable to do so. We aim to explain this reasoning further in our forthcoming Community Guide, but in short: The Daily Bruin is bound by journalistic <a href='https://www.spj.org/ethicscode.asp'>conflict of interest</a> standards and does not have the mandate as a <a href='https://campaign.dailybruin.com/'>partially student-funded</a> organization to redirect student fees outside our own operations.</li>
                    </ul>
                    </ul>
                    </ul>
                    I want so badly to promise perfection, but I know that would be disingenuous. We are unlikely to fully transform an institution as large as The Bruin in a single year. Our aim, instead, is to lay the groundwork so that this push for inclusivity can outlast this editor staff’s one-year terms.
                    <br></br>
                    <br></br>
                    This framework is made up of several changes that should have been implemented long ago but never materialized. I will not make excuses for past behavior. Instead, I give my word that the 2020-21 editor team is committed to seeing this through. It is our responsibility to make the absolute best possible effort to ensure stories from our community – our entire community – are heard.
                    <br></br>
                    <br></br>
                    If you have feedback on anything in this letter or anything that isn’t included but should be, we want to hear from you. Our team can be reached via email, social media or op-ed submission.
                    <br></br>
                    <br></br>
                    And we’ll be fixing that Community Guide.
                    <br></br>
                    <br></br>
                    Sincerely,
                    <br></br>
                    <br></br>
                    Melissa Yasuko Morris
                </div>
                ) : null}
            </div>
        );
    }
}
export default Collapsible;