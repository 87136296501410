import React, { useMemo } from "react";
import ArticleCard from "./ArticleCard";
import FillerCard from "./FillerCard";
import AdCard from "./AdCard";

/**
* props: {
	objects = [
		{type: "article", width: 2, img: "", author: "", description: ""}, // this one is twice as wide
		{type: "article", width: 1, img: "", author: "", description: ""},
		{type: "ad", idk yet}
	]

}
*/
function ArticleGrid(props) {
  // useMem memoizes the result so it doesn't have to calculated again
  // appendFiller: add empty boxes to the end of a row if there isn't enough. If we didn't do this,
  //    the cards in the last row will be aligned weirdly. If mobile, no need since everything is in 1 column
  // sortObjectsIfMobile: in mobile, we can't have big cards. But big cards are more important.
  //    so if mobile, we put big cards in front of the little cards.
  // clean: lowercases all field names just in case in kerckhoff, google docs auto capitialized it
  //    we should honestly have something like this for all the data we get from kerckhoff but
  //    i haven't figured out a clean way to do that yet
  if(!props.objects) return null

  let processedObjects = appendFiller(sortObjectsIfMobile(clean(props.objects)));
    
 

  return (
    <div className="article-grid">
      {processedObjects.map((x,index) => {

        if (x.type === "article") {
          return (
            <ArticleCard
              key={index}
              width={x.width}
              img={x.img}
              author={x.author}
              description={x.description}
              link={x.link}
            />
          );
        } else if (x.type === "ad") {
          return (
            <AdCard
              key={index}
              width={x.width}
              sponsor={x.sponsor}
              num={x.num}
            />
          );
        } else if (x.type === "filler") {
          return <FillerCard key={index} width={x.width} />;
        }
        return <h1>Incorrect Type of Content</h1>;
      })}
    </div>
  );
}

function isMobile() {
  return window.innerWidth < 768;
}

function appendFiller(objects) {
  if (isMobile())
    // in mobile, everything is in one column so no alignment
    return objects;

  
  let out = objects.map(x => x); 
  let count = objects.reduce((accum, x) => accum + Number(x.width), 0); // sum (x.width for all objects)

  if (count % 3 !== 0) {
    // if the last row didn't fill everything
    out.push({
      type: "filler",
      width: 3 - (count % 3) // fill the perfect size.
    });
  }
  return out;
}

function sortObjectsIfMobile(objects) {
  
  if (isMobile()) {
    return objects.sort(
      (a, b) => Number(b.width) - Number(a.width) // if b width is greater, it goes in front
    );
  } else {
    return objects;
  }
}

function clean(objects) {
  // lowercase fields
  return objects.map(x => {
    let out = {}

    for (let key in x) {
        // so it doesn't copy prototype properties
        if (x.hasOwnProperty(key)) {
          out[key.toLowerCase()] = x[key]
        }
    }
    return out;
  })
}

export default ArticleGrid;
