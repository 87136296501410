import React, {useState} from 'react'
import { css } from "emotion";
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';
import Imageuploader from './imageuploader';
import AvatarEditor from 'react-avatar-editor';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import Image from 'react-bootstrap/Image'
import { SecretCSS } from "./SecretCSS.css"
import Draggable from 'react-draggable'


class Secret extends React.Component {
    editor = AvatarEditor;
    constructor( props ) {
        super( props );
        this.state = {
         selectedFile: null,
         selectedFiles: null,
         displayState: 'u',
         buttontext: "Done",
         imageinfo: null,
         zoom: 1,
         editor_state: null,
         cropped_image: null,
         second_cropped_image: null,
         third_cropped_image: null,
         current_background: require("./gene.png"),
         num_images: 0,
         current_width: "15%",
         current_height: "auto",
         buttondisplaystate: "none"
        }
        this.changeDisplayState = this.changeDisplayState.bind(this)
        this.incrementZoom = this.incrementZoom.bind(this)
        this.decrementZoom = this.decrementZoom.bind(this)
        this.sendImageToBooth = this.sendImageToBooth.bind(this)
        this.setEditorRef = this.setEditorRef.bind(this)
        this.get_layers = this.get_layers.bind(this)
        this.removePhotos = this.removePhotos.bind(this)
        //this.updateBackground = this.updateBackground(this)
       }
    callbackFunction = (childData) => {
        this.setState({imageinfo: childData});
    }
    changeDisplayState () {
        if (this.state.displayState == 'u') {
            this.setState({displayState: 'a'});
            this.setState({buttondisplaystate: 'inline'})
            this.setState({buttontext: "Upload a different Imagae"});
        }
        else {
            this.setState({displayState: 'u'});
            this.setState({buttontext: "Done"});
        }
    }

    updateBackground(newbackground, backgroundtype) {
        this.setState({current_background: newbackground});
        if (backgroundtype == 0) { 
            //this.setState({current_width: "6vh", current_height: "3vx"})
            this.setState({current_width: "6%", current_height: "auto"})
        }
        else if (backgroundtype == 1) {
            //this.setState({current_width: "10vh", current_height: "8vx"})
            this.setState({current_width: "10%", current_height: "auto"})
        }
        else if (backgroundtype == 2) {
            //this.setState({current_width: "14vh", current_height: "12vx"})
            this.setState({current_width: "15%", current_height: "auto"})
        }
        else if (backgroundtype == 3) {
            //this.setState({current_width: "20vh", current_height: "18vx"})
            this.setState({current_width: "20%", current_height: "auto"})
        }
        else {
            //this.setState({current_width: "24vh", current_height: "20vx"})
            this.setState({current_width: "30%", current_height: "auto"})
        }
    }
    incrementZoom() {
            let currentZoom = this.state.zoom + 0.5;
            //console.log("inc zoom: ", currentZoom)
            this.setState({zoom: currentZoom})
    }
    decrementZoom() {
        if (this.state.zoom > 0.5) {
            let currentZoom = this.state.zoom - 0.5;
            this.setState({zoom: currentZoom})
        }
    }
    sendImageToBooth = () => {
        const editor = this.state.editor_state;
        if (editor) {
            const url = editor.getImageScaledToCanvas().toDataURL();
            //console.log("URL ", url)
            if (this.state.num_images == 0)
                this.setState({ cropped_image: url });
            else if(this.state.num_images == 1)
                this.setState({ second_cropped_image: url });
            else
            this.setState({ third_cropped_image: url });
            this.setState({num_images: this.state.num_images + 1});
            //this.setState({editor_state})
        }
    }
       
    get_layers = () => {
        
        let layers = [
            this.state.cropped_image
      ];
      return layers
    }
    removePhotos = () => {
        this.setState({cropped_image: null, second_cropped_image: null, third_cropped_image: null})
        this.setState({num_images: 0})
    }
    setEditorRef = (editor) => {console.log("HI ", editor); this.setState({editor_state: editor}, () => console.log("state ", this.state.editor_state))}
    component = null;
    
    render() {
        {{
            //console.log("Current state", currentState);
            let my_zoom = this.state.zoom;
            switch(this.state.displayState){
                case 'u': this.component = <Imageuploader 
                    parentCallBack={this.callbackFunction}
                />; break;
                case 'a': this.component = <div><AvatarEditor 
                    ref={this.setEditorRef}
                    image={this.state.imageinfo}
                    width={250}
                    height={300}
                    border={50}
                    color={[255, 255, 255, 0.6]} // RGBA
                    scale={1.2}
                    rotate={0}
                    borderRadius={400}
                    style={{width:"80%", height:"auto"}}
                    scale={my_zoom}/>
                    <br></br>
                    <button onClick={this.incrementZoom} style={{fontFamily:"Inconsolata", display:"inline"}}>Zoom In</button>
                    <button onClick={this.decrementZoom} style={{fontFamily:"Inconsolata", display:"inline"}}>Zoom Out</button>
                    <br></br>
                    </div>; 
                    break;
                default: this.component = <Imageuploader />;
        }
        }}
    
        return(
        <>
        <Container fluid>
        <Row align="start" debug>
            <Col style={{ textAlign: 'center', background: '#84A1B6', fontSize:"2.5em", borderColor:"white", fontFamily: "Inconsolata", paddingTop: "8px", paddingBottom: "8px"}} debug>Virtual UCLA Photobooth!</Col>
        </Row>
        <Row style={{background: 'white', borderColor:"white"}} debug>
            <Col style={{background: 'white', borderColor:"white", fontFamily: "Inconsolata"}} xs={5} md={5} debug><br></br>Click the square to upload and crop your photo!
            <React.StrictMode>
            <div>
            {this.component}
            </div>
            <div>
                <button style={{fontFamily:"Inconsolata"}} onClick={this.changeDisplayState}>{this.state.buttontext}</button>
                <br></br><button style={{fontFamily:"Inconsolata", display:this.state.buttondisplaystate}} onClick={this.sendImageToBooth}>Place Image</button>
            </div>
            </React.StrictMode>
      </Col> 
            <Col style={{background: 'white', borderColor:"white", fontFamily: "Inconsolata"}} xs={7} md={7} debug>
            <br></br>Drag up to three images onto the photobooth background
            <br></br><button style={{fontFamily:"Inconsolata"}} onClick={this.removePhotos}>remove photos</button>
            <br></br>
            <div>
            <div>
            <div>
            <Draggable>
            <img style={{position: "absolute", borderRadius:"50%",
                userDrag: "none",
                webkitUserDrag: "none",
                width: this.state.current_width,
                height: this.state.current_height,
                overflow: "hidden"}}
            src={this.state.cropped_image}></img>
            </Draggable> 
            <Draggable>
            <img style={{position: "absolute",borderRadius:"50%",
                userDrag: "none",
                webkitUserDrag: "none",
                width: this.state.current_width,
                height: this.state.current_height,
                overflow: "hidden"}}
            src={this.state.second_cropped_image}></img>
            </Draggable>
            <Draggable>
            <img style={{position: "absolute",borderRadius:"50%",
                userDrag: "none",
                webkitUserDrag: "none",
                width: this.state.current_width,
                height: this.state.current_height,
                overflow: "hidden"}}
            src={this.state.third_cropped_image}></img>
            </Draggable>
            <img className="backgroundimage" src={this.state.current_background} ></img>
            </div>
            <div>
            Click to try a different photobooth!
            </div>
            <img className="galleryimage" src={require('./grapeeggbackground.png')} onClick={() => this.updateBackground(require('./grapeeggbackground.png'), 1)}></img>
            <img className="galleryimage" src={require('./rosebowlbackground.png')} onClick={() => this.updateBackground(require('./rosebowlbackground.png'), 3)}></img>
            <img className="galleryimage" src={require('./bearbackground.png')} onClick={() => this.updateBackground(require('./bearbackground.png'), 0)}></img>
            <img className="galleryimage" src={require('./dininghallbackground.png')} onClick={() => this.updateBackground(require('./dininghallbackground.png'), 4)}></img>
            <img className="galleryimage" src={require('./gene.png')} onClick={() => this.updateBackground(require('./gene.png'), 2)}></img>
            </div>
            </div>
            </Col>
        </Row>
        </Container>
      </>
    )
  }
}
export default Secret