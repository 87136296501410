import React from "react";
import ArticleGrid from "./ArticleGrid"
import BannerAd from "./BannerAd"



export default function SectionPage(props) {
    const { section } = props;
    const mql = window.matchMedia(`(min-width: 800px)`);

    
  return (<div
  	id={section + "_Section"}
  	style={{ "margin-left": mql.matches ? 'min(13vw, 155px)' : '24vw', "margin-right": "30px" }}
  >
 	{section != "NEWS"? <BannerAd section={section} />: null} 
	<h1
	  style={{
		fontFamily: "Libre Baskerville",
		fontSize: "3em",
		color: "#84a1b6"
	  }}
	>
	  {section}
	</h1>
	<ArticleGrid objects={props.cards} />
	<div 
	  style={{
		width: "100%",
		"margin-bottom": "20px",
	  }}
	>
	  
	</div>
  </div>
);
  
}
